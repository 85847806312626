import React, { useEffect, useState } from 'react';
import DashboardHome from './DashboardHome';
import { Link } from 'react-router-dom';
import Support from './Support';
import { format } from 'date-fns';


const WithdrawalHistoryChildren = () => {
const [accessToken, setAccessToken] = useState();
  const [withdrawals, setWithdrawals] = useState({});
  const [transactions, setTransactions] = useState({});
  const[loading,setLoading] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setAccessToken(token);
  }, []);

  useEffect(() => {
    const fetchInvestments = async () => {
       setLoading(true)
      if (!accessToken) return;  // Ensure the accessToken is available before making the request
      const url1 = process.env.REACT_APP_WITHDRAWAL;
      const url2 = process.env.REACT_APP_DEPOSIT_TRANSACTION;
      const params = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${accessToken}`,
        }
      };
      try {
        const res1 = await fetch(url1, params);
        const res2 = await fetch(url2, params);
        const resObj1 = await res1.json();
        const resObj2 = await res2.json();
        setWithdrawals(resObj1?.data || {});
        setTransactions(resObj2?.data || {});
      } catch (error) {
        console.error("Error fetching withdrawals: ", error);
      }
      finally{
      setLoading(false)
      }
    };

    fetchInvestments();
  }, [accessToken]);  // Re-run the effect when accessToken changes



  const {count=0, transactions:data=[]} = withdrawals;
  const {count:transactionCount=0, transactions:transactionsData=[]} = transactions;

  const withdrawalData = data.map((item) => (
    <tr className="trade-data-row" key={item._id}>
      {/* <td>{item?.transactionType}</td> */}
      <td>{item?.walletAddress}</td>
      <td>{item?.confirmed === true ? <span className='verified'>success</span> : <span className='unverified'>pending</span> }</td>
      <td>{item?.network}</td>
      {/* <td>{new Date(item?.transactionDate).toUTCString()}</td> */}
      <td>{format(new Date(item?.transactionDate),"MMM d, yyyy h:mm a")}</td>
      <td>{`$ ${item?.amount}`}</td>
    </tr>
  ))
  const transactionData = transactionsData.map((item) => (
    <tr className="trade-data-row" key={item._id}>
      {/* <td>{item?.transactionType}</td> */}
      <td>{item?.transactionType}</td>
      <td>{item?.confirmed === true ? <span className='verified'>success</span> : <span className='unverified'>pending</span> }</td>
      <td>{item?.network}</td>
      <td>{format(new Date(item?.transactionDate),"MMM d, yyyy h:mm a")}</td>
      {/* <td>{new Date(item?.transactionDate).toUTCString()}</td> */}
      <td>{`$ ${item?.amount}`}</td>
    </tr>
  ))


    return(
        <div className="mt-20 withdraw-history-conatiner">
           <div className="investements-history">
            <h4 className='title'>transaction history</h4>
            <p className='mb-8'>
             The Transaction History section on Tetfi allows you to monitor all your transactions on the platform. This feature provides comprehensive details on each transaction, ensuring transparency and helping you manage your financial activities more efficiently.
            </p>
          
          <div className="all-withdrawal-request">
            <p className='title mb-4'>
            All Transaction Requests for this Account
            </p>
            {
          loading ? (<p className='mb-8 text-[var(--red-color)] font-[600] text-xl'>
          Loading Transactions...
        </p>) : (
          transactionData.length === 0 ? (<p className='mb-8 text-[var(--red-color)] font-[600] text-xl'>
            You currently have a total of {transactionCount} transaction.
          </p>)
          :
          (<p className='mb-8 text-[var(--teal-color)] font-[600] text-xl'>
            You currently have a total of {transactionCount} transactions.
          </p>)
        )
        }
            <div className="withdrawal-details mb-20">
          <div className="table-container">
            <table className="table-element">
              <thead className="thead">
                <tr className="trade-data-row">
                  {/* <th>type</th> */}
                  <th>type</th>
                  <th>status</th>
                  <th>network</th>
                  <th>Date</th>
                  <th>amount</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {transactionData}
              </tbody>
            </table>
          </div>
        </div>
        </div>
        </div>


          <div className="withdrawal-history">
            <h4 className='title'>withdrawal history</h4>
            <p className='mb-8'>
            The Withdrawal History section on Tetfi allows you to keep track of all your withdrawals from the platform. This feature provides detailed information on each transaction, ensuring transparency and helping you manage your finances more effectively.
            </p>
          
          <div className="all-withdrawal-request">
            <p className='title'>
            All Withdrawal Requests for this Account
            </p>
            <p>
            Here is a summary of all the withdrawals you’ve made so far. If you experience any issues with withdrawing funds, Please contact us for assistance 
            <Link className='inline-block text-[#2B6CB0]'  to="/contactus">here</Link>
            </p>
          {
           loading ? (<p className='mb-8 text-[var(--red-color)] font-[600] text-xl'>
          Loading Withdrawals...
        </p>) : (
          data.length === 0 ? (<p className='mb-8 text-[var(--red-color)] font-[600] text-xl'>
            You currently have a total of {count} Withdrawal.
          </p>)
          :
          (<p className='mb-8 text-[var(--teal-color)] font-[600] text-xl'>
            You currently have a total of {count} Withdrawals.
          </p>)
        )
        }
            <div className="withdrawal-details mb-20">
          <div className="table-container">
            <table className="table-element">
              <thead className="thead">
                <tr className="trade-data-row">
                  {/* <th>type</th> */}
                  <th>wallet address</th>
                  <th>status</th>
                  <th>network</th>
                  <th>Date</th>
                  <th>amount</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {withdrawalData}
              </tbody>
            </table>
          </div>
        </div>
        </div>
        {
        transactionData.length === 0 ? (
         <div className="withdrawal-summary-box message-box">
          <p className='message-contents'>
              You haven’t made any withdrawal requests with your Tetfi investment account yet. Begin earning by investing today— <Link className='inline-block p-0 text-[#2B6CB0] font-bold' to="/dashboard/invest">choose a suitable investment plan</Link> from our range of products. You can also earn withdrawable funds by <Link className='inline-block p-0 text-[#2B6CB0] font-bold' to='/dashboard/ref/#ref-link'>referring friends</Link>, family, and colleagues to open an investment account with Tetfi.
              </p>
        </div>
        )
        :
        <div className="support-team">
        <Support marginVal="0"/>
        </div>
        }
        </div>
        
        </div>
    )
}

function DashboardWithdrawalHistory() {
  return (
    <DashboardHome children={<WithdrawalHistoryChildren/>}/>
  )
}

export default DashboardWithdrawalHistory