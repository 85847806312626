import React, { useEffect, useState } from 'react';
import DashboardHome from './DashboardHome';
import { Link } from 'react-router-dom';
import Support from './Support';
import { format } from "date-fns"

const InvestmentsChildren = () => {
  const [accessToken, setAccessToken] = useState();
  const [transactions, setTransactions] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setAccessToken(token);
  }, []);

  useEffect(() => {
    const fetchInvestments = async () => {
      setLoading(true)
      if (!accessToken) return;  // Ensure the accessToken is available before making the request
      const url = process.env.REACT_APP_INVEST;
      const params = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${accessToken}`,
        }
      };
      try {
        const res = await fetch(url, params);
        const resObj = await res.json();
        setTransactions(resObj?.data || {});
      } catch (error) {
        console.error("Error fetching investments: ", error);
      }
      finally{
        setLoading(false)
      }
    };

    fetchInvestments();
  }, [accessToken]);  // Re-run the effect when accessToken changes

  // console.log(transactions);
  const {count=0, transactions:data=[]} = transactions;

  const transactionData = data.map((item) => (
    <tr className="trade-data-row" key={item._id}>
      <td>{item?.selectedPlan}</td>
      <td>{item?.investmentWallet}</td>
      {/* <td>{new Date(item?.investmentDate).toUTCString()}</td> */}
      <td>{format(new Date(item?.investmentDate),"MMM d, yyyy h:mm a")}</td>
      <td>{`$ ${item?.amount}`}</td>
    </tr>
  ))

  return (
    <div className="mt-20 investment-history-container">
      <div className="investment-history mb-8">
        <h4>Investment History</h4>
        <p className='mb-8'>
          The Investment History section on Tetfi allows you to review all your investments on the platform. This feature provides detailed information on each transaction, ensuring transparency and helping you manage your investments more effectively.
        </p>
        <div className="all-investment-request">
        <p className='title'>
          All Investment Requests for this Account
        </p>
        <p>
          Here is a summary of all the investments you’ve made so far. If you experience any issues with withdrawing funds, Please contact us for assistance 
          <Link className='inline-block text-[#2B6CB0]' to="/contactus">here</Link>
        </p>
      </div>
        {
        loading ? (<p className='mb-8 text-[var(--red-color)] font-[600] text-xl'>
          Loading Investemnets...
        </p>) : (
          data.length === 0 ? (<p className='mb-8 text-[var(--red-color)] font-[600] text-xl'>
            You currently have a total of {count} investments.
          </p>)
          :
          (<p className='mb-8 text-[var(--teal-color)] font-[600] text-xl'>
            You currently have a total of {count} investments.
          </p>)
        )
        }

        {/* Investment details */}
        <div className="investemet-details mb-20">
          <div className="table-container">
            <table className="table-element">
              <thead className="thead">
                <tr className="trade-data-row">
                  <th>selected plan</th>
                  <th>wallet</th>
                  <th>Date</th>
                  <th>amount</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {transactionData}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {
      data.length === 0 ? (
       <div className="investment-summary-box message-box">
       <p className='message-contents'>
         You haven’t made any investment requests with your Tetfi investment account yet. Begin earning by investing today— <Link className='inline-block p-0 text-[#2B6CB0] font-bold' to="/dashboard/invest">choose a suitable investment plan</Link> from our range of products. You can also earn withdrawable funds by <Link className='inline-block p-0 text-[#2B6CB0] font-bold' to='/dashboard/ref/#ref-link'>referring friends</Link>, family, and colleagues to open an investment account with Tetfi.
       </p>
     </div>
      )
      :
      (
        <div className="chat-support">
       <Support marginVal="0"/>
      </div>
      )
      }
    </div>
  );
};

function DashboardInvestments() {
  return (
    <DashboardHome children={<InvestmentsChildren />} />
  );
}

export default DashboardInvestments;
